* {
  --theme_dark_blue: #5052ba;
  --theme_light_blue: #d3d4ee;
  --theme-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dashboard-small-card {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
}

.dashboard-small-card .MuiSvgIcon-root {
  font-size: 30px;
}

.icon-body {
  background-color: var(--theme_light_blue);
  padding: 10px;
  border-radius: 10px;
}

.card-text-total {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.card-text-data {
  font-size: 15px !important;
  color: seagreen;
}
