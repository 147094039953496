:root {
  --theme-dark-blue: #5052ba;
  --theme-light-blue: #d3d4ee;
  --theme-dark-grey: #ffffff29; /* New color variable */
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f6f5fa !important;
  font-family: "Arial, sans-serif";
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--theme-dark-grey); /* Use the new color variable */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--theme-dark-blue);
  border-radius: 15px;
  height: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--theme-light-blue);
  max-height: 10px;
}
